import { Record } from "./fable_modules/fable-library.3.6.2/Types.js";
import { class_type, list_type, record_type, int32_type, string_type } from "./fable_modules/fable-library.3.6.2/Reflection.js";
import { mapIndexed, contains as contains_1, map } from "./fable_modules/fable-library.3.6.2/Array.js";
import { tail, head, isEmpty as isEmpty_1, length, take, append, cons, mapIndexed as mapIndexed_1, singleton as singleton_1, empty as empty_2, ofArray as ofArray_2, map as map_2, contains, filter } from "./fable_modules/fable-library.3.6.2/List.js";
import { map as map_3, singleton, collect, delay, toList } from "./fable_modules/fable-library.3.6.2/Seq.js";
import { min, numberHash, comparePrimitives, structuralHash, stringHash } from "./fable_modules/fable-library.3.6.2/Util.js";
import { add, empty as empty_1, tryFind, ofArray as ofArray_1, map as map_1, FSharpMap__get_Item, containsKey } from "./fable_modules/fable-library.3.6.2/Map.js";
import { rangeDouble } from "./fable_modules/fable-library.3.6.2/Range.js";
import { intersect, isEmpty, empty, ofList, ofArray } from "./fable_modules/fable-library.3.6.2/Set.js";
import { Array_groupBy } from "./fable_modules/fable-library.3.6.2/Seq2.js";
import { defaultArg } from "./fable_modules/fable-library.3.6.2/Option.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.57.0/React.fs.js";
import words from "./words.json";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.1.57.0/Interop.fs.js";
import { printf, toText } from "./fable_modules/fable-library.3.6.2/String.js";
import { RouterModule_router, RouterModule_urlSegments } from "./fable_modules/Feliz.Router.3.8.0/Router.fs.js";

export class FixedLetter extends Record {
    constructor(Letter, Position) {
        super();
        this.Letter = Letter;
        this.Position = (Position | 0);
    }
}

export function FixedLetter$reflection() {
    return record_type("App.FixedLetter", [], FixedLetter, () => [["Letter", string_type], ["Position", int32_type]]);
}

export class IncludeLetter extends Record {
    constructor(Letter, InvalidPositions) {
        super();
        this.Letter = Letter;
        this.InvalidPositions = InvalidPositions;
    }
}

export function IncludeLetter$reflection() {
    return record_type("App.IncludeLetter", [], IncludeLetter, () => [["Letter", string_type], ["InvalidPositions", list_type(int32_type)]]);
}

export class WordleState extends Record {
    constructor(Words, FixedLetters, ExcludeLetters, IncludeLetters, Filter) {
        super();
        this.Words = Words;
        this.FixedLetters = FixedLetters;
        this.ExcludeLetters = ExcludeLetters;
        this.IncludeLetters = IncludeLetters;
        this.Filter = Filter;
    }
}

export function WordleState$reflection() {
    return record_type("App.WordleState", [], WordleState, () => [["Words", list_type(string_type)], ["FixedLetters", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [int32_type, string_type])], ["ExcludeLetters", string_type], ["IncludeLetters", list_type(IncludeLetter$reflection())], ["Filter", string_type]]);
}

export function WordleState_filterCandidates_598513A(state) {
    const excludedCharactersArr = map((x) => x, state.ExcludeLetters.split(""));
    const fixedLetters = state.FixedLetters;
    return filter((w) => {
        const chars = map((x_1) => x_1, w.split(""));
        const letterComparison = !contains(false, toList(delay(() => collect((i) => {
            const currentChar = chars[i];
            return contains_1(currentChar, excludedCharactersArr, {
                Equals: (x_2, y) => (x_2 === y),
                GetHashCode: (x_2) => stringHash(x_2),
            }) ? singleton(false) : ((containsKey(i, fixedLetters) && (FSharpMap__get_Item(fixedLetters, i) !== currentChar)) ? singleton(false) : singleton(true));
        }, rangeDouble(0, 1, 4)))), {
            Equals: (x_3, y_1) => (x_3 === y_1),
            GetHashCode: (x_3) => structuralHash(x_3),
        });
        const wordIndicesLookup = map_1((_arg1, grp) => ofArray(map((tuple_1) => tuple_1[1], grp, Int32Array), {
            Compare: (x_6, y_3) => comparePrimitives(x_6, y_3),
        }), ofArray_1(Array_groupBy((tuple) => tuple[0], mapIndexed((x_4, c) => [c, x_4], chars), {
            Equals: (x_5, y_2) => (x_5 === y_2),
            GetHashCode: (x_5) => stringHash(x_5),
        })));
        if (letterComparison) {
            return !contains(false, map_2((includeLetter) => {
                const indices = ofList(includeLetter.InvalidPositions, {
                    Compare: (x_7, y_4) => comparePrimitives(x_7, y_4),
                });
                const letterIndices = defaultArg(tryFind(includeLetter.Letter, wordIndicesLookup), empty({
                    Compare: (x_8, y_5) => comparePrimitives(x_8, y_5),
                }));
                const exists = !isEmpty(letterIndices);
                if (isEmpty(intersect(indices, letterIndices))) {
                    return exists;
                }
                else {
                    return false;
                }
            }, state.IncludeLetters), {
                Equals: (x_9, y_6) => (x_9 === y_6),
                GetHashCode: (x_9) => structuralHash(x_9),
            });
        }
        else {
            return false;
        }
    }, state.Words);
}

export class Components {
    constructor() {
    }
}

export function Components$reflection() {
    return class_type("App.Components", void 0, Components);
}

export function Components_WordleSolver() {
    let children_2, children, children_6, children_4, children_10, children_14;
    const patternInput = useFeliz_React__React_useState_Static_1505(new WordleState(ofArray_2(words), empty_1(), "", empty_2(), ""));
    const state = patternInput[0];
    const setState = patternInput[1];
    const filteredCandidates = filter((w) => (w.indexOf(state.Filter) >= 0), WordleState_filterCandidates_598513A(state));
    return createElement("div", {
        className: "flex flex-col gap-4",
        children: Interop_reactApi.Children.toArray([createElement("h1", {
            children: "Wordle Solver",
            className: "w-full text-center font-bold text-4xl",
        }), (children_2 = ofArray_2([(children = singleton_1(createElement("h2", {
            children: ["Solved"],
        })), createElement("label", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), createElement("div", {
            className: "flex gap-2 justify-around",
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => map_3((i) => createElement("div", {
                className: "flex-0 w-12",
                children: Interop_reactApi.Children.toArray([createElement("input", {
                    className: "rounded w-full text-gray-800 text-center",
                    value: defaultArg(tryFind(i, state.FixedLetters), ""),
                    maxLength: 1,
                    onChange: (ev) => {
                        setState(new WordleState(state.Words, add(i, ev.target.value.toLocaleUpperCase(), state.FixedLetters), state.ExcludeLetters, state.IncludeLetters, state.Filter));
                    },
                })]),
            }), rangeDouble(0, 1, 4)))))),
        })]), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        })), (children_6 = ofArray_2([(children_4 = singleton_1(createElement("h2", {
            children: ["Exclude letters: "],
        })), createElement("label", {
            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
        })), createElement("input", {
            className: "w-full rounded text-gray-800",
            onChange: (ev_1) => {
                setState(new WordleState(state.Words, state.FixedLetters, ev_1.target.value.toLocaleUpperCase(), state.IncludeLetters, state.Filter));
            },
        })]), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_6)),
        })), createElement("div", {
            className: "flex gap-2 flex-col",
            children: Interop_reactApi.Children.toArray([createElement("label", {
                children: Interop_reactApi.Children.toArray(["Include letters"]),
            }), createElement("div", {
                className: "flex flex-col gap-2",
                children: Interop_reactApi.Children.toArray(Array.from(mapIndexed_1((index, includeLetter) => createElement("div", {
                    className: "flex gap-2",
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        className: "flex gap-1 flex-col",
                        children: Interop_reactApi.Children.toArray([createElement("label", {
                            children: "Letter:",
                        }), createElement("input", {
                            className: "rounded w-12 text-gray-800 text-center",
                            value: includeLetter.Letter,
                            maxLength: 1,
                            onChange: (ev_2) => {
                                const v_2 = ev_2.target.value;
                                setState(new WordleState(state.Words, state.FixedLetters, state.ExcludeLetters, mapIndexed_1((i_1, il$0027) => {
                                    if (i_1 === index) {
                                        return new IncludeLetter(v_2.toLocaleUpperCase(), il$0027.InvalidPositions);
                                    }
                                    else {
                                        return il$0027;
                                    }
                                }, state.IncludeLetters), state.Filter));
                            },
                        })]),
                    }), createElement("div", {
                        className: "flex-1 flex flex-col gap-1",
                        children: Interop_reactApi.Children.toArray([createElement("label", {
                            children: "Invalid positions:",
                        }), createElement("div", {
                            className: "flex gap-1 justify-between",
                            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => map_3((i_2) => {
                                let arg10_1, arg10_2;
                                return createElement("label", {
                                    htmlFor: toText(printf("invalid-position-%i-%i"))(index)(i_2),
                                    children: Interop_reactApi.Children.toArray([createElement("div", {
                                        className: (arg10_1 = (contains(i_2, includeLetter.InvalidPositions, {
                                            Equals: (x, y) => (x === y),
                                            GetHashCode: (x) => numberHash(x),
                                        }) ? "bg-red-700" : "bg-green-700"), toText(printf("rounded w-12 h-12 p-3 leading-12 %s text-center"))(arg10_1)),
                                        children: (arg10_2 = ((i_2 + 1) | 0), toText(printf("%i"))(arg10_2)),
                                    }), createElement("input", {
                                        className: "invisible",
                                        name: toText(printf("invalid-position-%i-%i"))(index)(i_2),
                                        id: toText(printf("invalid-position-%i-%i"))(index)(i_2),
                                        type: "checkbox",
                                        checked: contains(i_2, includeLetter.InvalidPositions, {
                                            Equals: (x_1, y_1) => (x_1 === y_1),
                                            GetHashCode: (x_1) => numberHash(x_1),
                                        }),
                                        onChange: (ev_3) => {
                                            const includeLetter$0027 = new IncludeLetter(includeLetter.Letter, ev_3.target.checked ? cons(i_2, includeLetter.InvalidPositions) : filter((x_2) => (x_2 !== i_2), includeLetter.InvalidPositions));
                                            setState(new WordleState(state.Words, state.FixedLetters, state.ExcludeLetters, mapIndexed_1((i_3, il$0027_1) => {
                                                if (i_3 === index) {
                                                    return includeLetter$0027;
                                                }
                                                else {
                                                    return il$0027_1;
                                                }
                                            }, state.IncludeLetters), state.Filter));
                                        },
                                    })]),
                                });
                            }, rangeDouble(0, 1, 4)))))),
                        })]),
                    })]),
                }), state.IncludeLetters))),
            }), (children_10 = singleton_1(createElement("button", {
                children: "Add include letter",
                className: "p-3 bg-green-700 text-white font-bold rounded",
                onClick: (_arg2) => {
                    setState(new WordleState(state.Words, state.FixedLetters, state.ExcludeLetters, append(state.IncludeLetters, singleton_1(new IncludeLetter("", empty_2()))), state.Filter));
                },
            })), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_10)),
            }))]),
        }), (children_14 = ofArray_2([createElement("label", {
            children: Interop_reactApi.Children.toArray(["Filter: "]),
        }), createElement("input", {
            className: "w-full rounded text-gray-800 p-2",
            onChange: (ev_4) => {
                setState(new WordleState(state.Words, state.FixedLetters, state.ExcludeLetters, state.IncludeLetters, ev_4.target.value.toLocaleUpperCase()));
            },
        })]), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_14)),
        })), createElement("div", {
            children: Interop_reactApi.Children.toArray([createElement("h2", {
                className: "font-bold text-2xl",
                children: "Candidate words",
            }), createElement("div", {
                className: "flex gap-4 flex-wrap justify-between",
                children: Interop_reactApi.Children.toArray(Array.from(map_2((w_1) => createElement("span", {
                    children: [w_1],
                }), take(min((x_3, y_2) => comparePrimitives(x_3, y_2), 100, length(filteredCandidates)), filteredCandidates)))),
            })]),
        })]),
    });
}

export function Components_Counter() {
    const patternInput = useFeliz_React__React_useState_Static_1505(0);
    const count = patternInput[0] | 0;
    const children = ofArray_2([createElement("h1", {
        children: [count],
    }), createElement("button", {
        onClick: (_arg3) => {
            patternInput[1](count + 1);
        },
        children: "Increment",
    })]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function Components_Router() {
    const patternInput = useFeliz_React__React_useState_Static_1505(RouterModule_urlSegments(window.location.hash, 1));
    const currentUrl = patternInput[0];
    return RouterModule_router({
        onUrlChanged: patternInput[1],
        application: react.createElement(react.Fragment, {}, ...toList(delay(() => ((!isEmpty_1(currentUrl)) ? ((head(currentUrl) === "counter") ? (isEmpty_1(tail(currentUrl)) ? singleton(createElement(Components_Counter, null)) : singleton(createElement("h1", {
            children: ["Not found"],
        }))) : singleton(createElement("h1", {
            children: ["Not found"],
        }))) : singleton(createElement("h1", {
            children: ["Index"],
        })))))),
    });
}

